<template>
  <section class="help-container">
    <h3 class="okta-form-title o-form-head font-condensed" data-se="o-form-head">
      {{ $t('mfaSms.pageTitle') }}
    </h3>
    <b-overlay
      :blur="$root.globalConfig.overlay.blur"
      :opacity="$root.globalConfig.overlay.opacity"
      :show="!user || loading"
      :spinner-variant="$root.globalConfig.overlay.spinnerVariant"
      :variant="$root.globalConfig.overlay.variant"
      no-fade
      rounded
      z-index="100"
    >
      <div class="clearfix mb-4">
        <p class="text-center">{{ $t('mfaSms.description') }}</p>
        <b-container class="phonenumber-input mb-4">
          <b-row>
            <b-col>
              <label for="phoneNumber" class="o-form-label">{{ $t('mfaSms.phoneNumber') }}</label>
            </b-col>
          </b-row><b-row>
            <b-col cols="2" class="phone-prefix pt-2 pb-1">
              <div class="mb-0 align-middle">+1</div>
            </b-col>
            <b-col cols="5">
              <b-form-input id="phoneNumber" v-model="phoneNumber" class="mr-1 font-base" @change="hideThePassCodeInput" />
            </b-col><b-col cols="5">
              <b-button class="button button-primary ml-1" type="button" @click="sendCode">{{ $t('mfaSms.sendCode') }}</b-button>
            </b-col>
          </b-row>
          <b-row>
            <FormError :error="validation.phoneNumber" mode="infobox" />
          </b-row>
        </b-container>
        <div v-if="codeSent">
          <hr class="my-4">
          <div class="verificationCode-input form-error-row">
            <label for="verificationCode" class="o-form-label">{{ $t('mfaSms.enterCode') }}</label>
            <b-form-input id="verificationCode" v-model="verificationCode" />
            <FormError :error="validation.verificationCode" mode="infobox" />
            <div v-if="resendPromptVisible" class="resend-code">
              <a href="#" @click="resendPasscode">{{ $t('mfaSms.resendCode') }}</a>
            </div>
            <b-button class="button button-primary my-4" type="button" @click="verifyPassCode">{{ $t('mfaSms.verify') }}</b-button>
          </div>
        </div>
        <div class="o-form-button-bar">
          <b-button
            class="button button-secondary text-primary"
            type="button"
            @click="goBack()"
          >
            {{ $t('common.goBack') }}
          </b-button>
        </div>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import {iamService} from '@/services/iamService'
import {phone} from 'phone'
import FormError from '@/components/helpers/FormError.vue'

const FACTOR_TYPE = 'sms'
const PHONE_NUMBER_PREFIX = '+1'
const STATUS_PENDING_ACTIVATION = 'PENDING_ACTIVATION'
const STATUS_ACTIVE = 'ACTIVE'
// okta allows resending the code after 30s, I've added 5s in case when the API response is delayed
const MILIS_TO_WAIT_BEFORE_RESEND = 35000

export default {
  components: {
    FormError
  },
  props: {
    authenticated: {
      default: false
    },
    user: {
      type: Object,
      default: null
    },
    tokens: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      phoneNumber: '',
      verificationCode: '',
      codeSent: false,
      timer: false,
      resendPromptVisible: false,
      factorId: '',
      validation: {
        phoneNumber: false,
        verificationCode: false
      }
    }
  },
  computed: {
    requestConfig() {
      return {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.tokens.accessToken
        }
      }
    }
  },
  beforeDestroy() {
    this.clearResendTimer()
  },
  methods: {
    async init() {
      this.loading = true
      if (this.$route.meta.requiresAuth) {
        await this.setupTokens()
      }
    },
    async setupTokens() {
      this.authenticated = await this.$auth.isAuthenticated()
      this.loading = false
      if (!this.authenticated) return
      this.tokens.accessToken = this.$auth.getAccessToken()
      this.loading = false
    },
    clearResendTimer() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = false
    },
    hideThePassCodeInput() {
      this.codeSent = false
      this.clearResendTimer()
      this.resendPromptVisible = false
    },
    validatePhoneNumber(phoneNumber) {
      const validationResult = phone(phoneNumber)
      return validationResult.isValid ? validationResult.phoneNumber : null
    },
    translateEnrollErrorMessage(message) {
      if (message.includes('already set up')) {
        return this.$t('mfaSms.alreadySetUp')
      } else if (message.includes('429')) {
        return this.$t('common.tooManyRequests')
      } else if (message.includes('phone number') || message.includes('400')){
        return this.$t('mfaSms.invalidPhoneNumberFormat')
      } else if (message.includes('403')) {
        return this.$t('mfaSms.invalidCountry')
      } else {
        return this.$t('mfaSms.error')
      }
    },
    validatePassCode(passCode) {
      return !!passCode && !!passCode.length
    },
    translateActivateErrorMessage(message) {
      if (message.includes('403')) {
        return this.$t('mfaSms.invalidPassCode')
      } else if (message.includes('429')) {
        return this.$t('common.tooManyRequests')
      } else {
        return this.$t('mfaSms.error')
      }
    },
    waitAndShowResendPrompt() {
      this.clearResendTimer()
      this.timer = setInterval(() => {
        this.resendPromptVisible = true
        clearInterval(this.timer)
      }, MILIS_TO_WAIT_BEFORE_RESEND)
    },
    async resendPasscode() {
      await iamService.resendMfaChallenge(this.factorId, FACTOR_TYPE, this.requestConfig)
      this.resendPromptVisible = false
      this.waitAndShowResendPrompt()
    },
    async sendCode() {
      try {
        if (this.codeSent) {
          return
        }
        const rawPhoneNumber = PHONE_NUMBER_PREFIX + this.phoneNumber
        const phoneNumber = this.validatePhoneNumber(rawPhoneNumber)
        this.validation.phoneNumber = phoneNumber ? '' : this.$t('mfaSms.invalidPhoneNumberFormat')
        if (!phoneNumber) {
          return
        }
        const response = await iamService.enrollUserToMfaFactor(FACTOR_TYPE, {phoneNumber}, this.requestConfig)
        this.validation.phoneNumber = ''
        if (response.data._value.status === STATUS_PENDING_ACTIVATION) {
          this.factorId = response.data._value.id
          this.codeSent = true
          this.waitAndShowResendPrompt()
        }
        else if (response.data._value.status === STATUS_ACTIVE) {
          this.goBack()
        }
      } catch (e) {
        const message = e?.response?.data?.error?.message || e.message || ''
        this.validation.phoneNumber = this.translateEnrollErrorMessage(message)
      }
    },
    async verifyPassCode() {
      try {
        this.validation.verificationCode = ''
        const code = this.verificationCode
        if (!this.validatePassCode(code)) {
          this.validation.verificationCode = this.$t('mfaSms.invalidPassCodeFormat')
          return
        }
        await iamService.activateMfaFactor(this.factorId, code, this.requestConfig)
        this.validation.verificationCode = ''
        this.goBack()
      } catch(e) {
        this.validation.verificationCode = this.translateActivateErrorMessage(e.message)
      }
    },
    goBack() {
      this.$router.push('/app/mfa-settings')
    }
  }
}
</script>
<style lang="scss" scoped>

  @import '../scss/colors';
  .phone-prefix {
    color: $gray-700 !important;
    background-color: $gray-300 !important;
    text-align: center;
    border: 1px solid $gray-800 !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
    font-size: 1rem !important;
  }

  #phoneNumber {
    border-radius: 0 0.25rem 0.25rem 0 !important;
    height: 100%;
  }

  button {
    width: 100%;
  }

  .o-form-has-errors {
    margin-top: 2rem !important;
  }
</style>
