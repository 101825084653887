<template>
  <section class="help-container">
    <div v-if="warningMessage !== ''">
      <div class="infobox infobox-warning" role="alert">
        <span class="icon warning-16" />
        <p>{{ warningMessage }}</p>
      </div>
    </div>
    <b-overlay :show="true"
               no-fade
               rounded
               z-index="100"
               :blur="$root.globalConfig.overlay.blur"
               :opacity="$root.globalConfig.overlay.opacity"
               :variant="$root.globalConfig.overlay.variant"
               :spinner-variant="$root.globalConfig.overlay.spinnerVariant"
    >
      <div>
        <form :action="dsaUrl" method="POST">
          <input type="hidden" name="DealerType" :value="dealerType">
          <input type="hidden" name="DealerId" :value="dealerId">
          <input type="hidden" name="DealerCountry" :value="dealerCountry">
          <input type="hidden" name="DealerState" :value="dealerState">
        </form>
      </div>
    </b-overlay>
  </section>
</template>

<script>
import {auroraService} from '@/services/auroraService'
import {isInternalUser} from '@/utils'
import apiUrls from '@/api/apiUrls'

const DEFAULT_DEALER_ID = 'BOBCAT'
const DEFAULT_DEALER_TYPE = 'ALL'
const DEFAULT_DEALER_COUNTRY = 'US'
const DEFAULT_DEALER_STATE = 'ND'

const ORGANIZATION_TIMEOUT_MILIS = 10000
const SHOW_ERROR_FOR_MILIS = 3500

const VIEW_ALL_ORGANIZATIONS_ROLE = 'INTERNAL_VIEW_ALL_ORGANIZATIONS'

export default {
  data() {
    return {
      tokens: {},
      organizations: null,
      dealerType: DEFAULT_DEALER_TYPE,
      dealerId: DEFAULT_DEALER_ID,
      dealerCountry: DEFAULT_DEALER_COUNTRY,
      dealerState: DEFAULT_DEALER_STATE,
      warningMessage: ''
    }
  },
  computed: {
    requestConfig() {
      return {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.tokens.accessToken}`
        },
        timeout: ORGANIZATION_TIMEOUT_MILIS
      }
    }
  },
  async created() {
    this.dsaUrl = apiUrls.DSA_URL
  },
  async mounted() {
    await this.init()
    this.redirectToDsa()
  },
  methods: {
    async init() {
      if (this.$route.meta.requiresAuth) {
        await this.setupTokens()
      }
      await this.handleUserTypeLogic()
      this.updateDsaParameters()
    },
    async setupTokens() {
      this.authenticated = await this.$auth.isAuthenticated()
      if (!this.authenticated)
        return
      this.tokens.accessToken = this.$auth.getAccessToken()
      this.tokens.parsedIdToken = (JSON.parse(localStorage.getItem('okta-token-storage') || '{}')).idToken
    },
    displayWarningMessage(message) {
      this.warningMessage = message
    },
    async waitForMilis(milis) {
      return new Promise(resolve => setTimeout(resolve, milis))
    },
    hasViewAllOrganizationsRole() {
      return (this.tokens.parsedIdToken?.claims?.passportWebRoles || []).includes(VIEW_ALL_ORGANIZATIONS_ROLE)
    },
    async handleUserTypeLogic() {
      const isInternal = isInternalUser(this.tokens.parsedIdToken?.claims.directory_group || [])
      if (isInternal && !this.hasViewAllOrganizationsRole()) {
        this.displayWarningMessage(this.$t('dsa.internalUserNoRole'))
        this.organizations = []
        await this.waitForMilis(SHOW_ERROR_FOR_MILIS)
      } else {
        await this.fetchOrganizations(isInternal)
      }
    },
    async fetchOrganizations(isInternal) {
      try {
        this.organizations = isInternal ? (await auroraService.searchOrganizations(this.requestConfig)).data.organizations
          : (await auroraService.fetchOrgDescendants(this.requestConfig)).data
      }
      catch (_) {
        this.organizations = []
        this.displayWarningMessage(this.$t('dsa.organizationsApiError'))
        await this.waitForMilis(SHOW_ERROR_FOR_MILIS)
      }
    },
    updateDsaParameters() {
      if (this.organizations && this.organizations.length) {
        const [org] = this.organizations
        this.dealerId = org.arNumber || org.referenceId || DEFAULT_DEALER_ID
        this.dealerCountry = org.primaryShipToCountry || org.primaryBillToCountry || DEFAULT_DEALER_COUNTRY
        this.dealerState = org.primaryShipToState || org.primaryBillToState || DEFAULT_DEALER_STATE
      }
    },
    redirectToDsa() {
      document.forms[0].submit()
    }
  }
}
</script>
