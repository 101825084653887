import Vue from 'vue'
import Router from 'vue-router'

import IStoreSelectAccount from '@/components/IStoreSelectAccount'
import DsaForm from '@/components/DSAForm'
import DoosanWrapper from '@/components/helpers/DoosanWrapper'
import DoosanInvite from '@/components/DoosanInvite'
import DoosanInviteDealer from '@/components/DoosanInviteDealer'
import GenericConceptComponent from '@/components/GenericConceptComponent'
import RegionAwareConceptComponent from '@/components/RegionAwareConceptComponent'
import TocDocumentComponent from '@/components/TocDocumentComponent'
import TocAcceptanceComponent from '@/components/TocAcceptanceComponent'
import MyAccountComponent from '@/components/MyAccountComponent'
import MfaSettingsComponent from '@/components/MfaSettingsComponent'
import MfaSmsComponent from '@/components/MfaSmsComponent'
import MfaEmailComponent from '@/components/MfaEmailComponent'
import MfaOktaVerifyComponent from '@/components/MfaOktaVerifyComponent'
import RequestAccess from '@/components/RequestAccess'
import Dashboard from '@/components/Dashboard'
import Error from '@/components/Error'

import {LoginCallback} from '@okta/okta-vue'
import oktaConfig from '@/config'
import InterruptAppAnnouncementsDisplay from '@/components/InterruptAppAnnouncementsDisplay'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: request => {
        if (request.query && request.query.client_id) {
          // backward compatibility if someone still use account page...
          window.location.href =
            `${oktaConfig.oktaHostedUrl}/oauth2/${oktaConfig.authorizationServer}/v1/authorize` +
            request.fullPath
        } else {
          window.location.href = oktaConfig.oktaHostedUrl + request.fullPath
        }
      }
    },
    {
      path: '/app/mfa-settings',
      alias: '/mfa-settings',
      component: DoosanWrapper,
      props: {
        wraps: MfaSettingsComponent
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/app/mfa-settings/sms',
      component: DoosanWrapper,
      props: {
        wraps: MfaSmsComponent
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/app/mfa-settings/email',
      component: DoosanWrapper,
      props: {
        wraps: MfaEmailComponent
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/app/mfa-settings/okta-verify',
      component: DoosanWrapper,
      props: {
        wraps: MfaOktaVerifyComponent
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/activate',
      component: DoosanWrapper,
      props: {
        wraps: DoosanInvite
      }
    },
    {
      path: '/activateDealer',
      component: DoosanWrapper,
      props: {
        wraps: DoosanInviteDealer
      }
    },
    {
      path: '/help/password-reset',
      component: DoosanWrapper,
      props: {
        wraps: GenericConceptComponent,
        conceptName: 'passwordReset'
      }
    },
    {
      path: '/app/toc/:brand/:region/:channel/:application/:title',
      component: DoosanWrapper,
      props: {
        wraps: TocDocumentComponent,
        wide: true
      }
    },
    {
      path: '/app/toc/:region(na|emea|alao)/:channel/:application/:title',
      component: DoosanWrapper,
      props: {
        brand: 'bobcat',
        wraps: TocDocumentComponent,
        wide: true
      }
    },
    {
      path: '/app/toc/:brand/:region/:channel/:title',
      alias: '/toc/:brand/:region/:channel/:title',
      component: DoosanWrapper,
      props: {
        wraps: TocDocumentComponent,
        wide: true
      }
    },
    {
      path: '/app/toc/:region(na|emea|alao)/:channel/:title',
      alias: '/toc/:region/:channel/:title',
      component: DoosanWrapper,
      props: {
        brand: 'bobcat',
        wraps: TocDocumentComponent,
        wide: true
      }
    },
    {
      path: '/app/toc', // TODO: when the language URL will be introduced remove this section as it's not possible to have nonparam path and param alias
      alias: '/toc',
      component: DoosanWrapper,
      props: {
        wraps: TocAcceptanceComponent
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/:forcedLanguage/toc',
      component: DoosanWrapper,
      props: {
        wraps: TocAcceptanceComponent
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/implicit/callback',
      component: DoosanWrapper,
      props: {
        wraps: LoginCallback,
        loadingIndicator: true
      }
    },
    {
      path: '/app/my-account',
      alias: '/my-account',
      component: DoosanWrapper,
      props: {
        wraps: MyAccountComponent,
        brand: 'bobcat'
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/error',
      component: DoosanWrapper,
      props: {
        wraps: Error
      }
    },
    {
      path: '/app/request-access',
      alias: 'request-access',
      component: DoosanWrapper,
      props: {
        wraps: RequestAccess
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/app/dashboard',
      alias: '/dashboard',
      component: Dashboard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/app/istore',
      component: DoosanWrapper,
      props: {
        wraps: IStoreSelectAccount
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/app/dsa',
      component: DoosanWrapper,
      props: {
        wraps: DsaForm
      },
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/app/content/:region/:conceptPath/:conceptName',
      component: DoosanWrapper,
      props: {
        wraps: RegionAwareConceptComponent,
        wide: true
      }
    },
    {
      path: '/app/announcements',
      component: DoosanWrapper,
      props: {
        wraps: InterruptAppAnnouncementsDisplay
      },
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '*',
      redirect: () => {
        window.location.href = '/'
      }
    }
  ]
})
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !(await Vue.prototype.$auth.isAuthenticated())) {
    await Vue.prototype.$auth.signInWithRedirect({originalUri: to.fullPath})
  } else {
    next()
  }
})

export default router
