import {OktaAuth} from "@okta/okta-auth-js";
import oktaConfig from "@/config";

const isGeith = window.location.origin.includes('geith')
const oktaHostedUrl = isGeith ? oktaConfig.oktaGeithHostedUrl : oktaConfig.oktaHostedUrl

const oktaAuth = new OktaAuth({
  url: oktaConfig.oktaOrgUrl,
  issuer: `${oktaConfig.oktaOrgUrl}/oauth2/${oktaConfig.authorizationServer}`,
  clientId: isGeith ? oktaConfig.geithClientId : oktaConfig.bobcatClientId,
  redirectUri: `${window.location.origin}/implicit/callback`,
  scopes: ['openid', 'profile', 'email', 'login', 'passport:self', 'offline_access'],
  pkce: true,
  authorizeUrl: `${oktaHostedUrl}/oauth2/${oktaConfig.authorizationServer}/v1/authorize`,
  userinfoUrl: `${oktaHostedUrl}/oauth2/${oktaConfig.authorizationServer}/v1/userinfo`,
  tokenUrl: `${oktaHostedUrl}/oauth2/${oktaConfig.authorizationServer}/v1/token`,
  revokeUrl: `${oktaHostedUrl}/oauth2/${oktaConfig.authorizationServer}/v1/revoke`,
  logoutUrl: `${oktaHostedUrl}/oauth2/${oktaConfig.authorizationServer}/v1/logout`,
  postLogoutRedirectUri: window.location.origin,
  cookies: {secure: true},
  tokenManager: {
    autoRenew: true,
    autoRemove: false,
    storageKey: oktaConfig.tokenManagerKey
  },
  services: {autoRenew: false}
})

export default oktaAuth
